import * as React from 'react';
import PropTypes from 'prop-types';
// @mui
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import CopyToClipboard from 'react-copy-to-clipboard';

import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
// components
import {
  Card,
  Stack,
  Dialog,
  Button,
  Divider,
  Tooltip,
  CardMedia,
  Typography,
  IconButton,
  ToggleButton,
  DialogContent,
  ToggleButtonGroup,
} from '@mui/material';

import { USDT } from 'src/hooks/use-USDT';
import { useResponsive } from 'src/hooks/use-responsive';
// import { useGetRoles } from 'src/hooks/useHandleSessions';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import share from 'src/assets/other/share.svg';
import { updateWithdrawalRequestAdmin } from 'src/server/api/withdrawalRequest';

import Label from 'src/components/label';
import TextMaxLine from 'src/components/text-max-line';
import { RHFTextField } from 'src/components/hook-form';
import { useSettingsContext } from 'src/components/settings';
import FormProvider from 'src/components/hook-form/form-provider';

WithdrawalRequestApprove.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.object,
  view: PropTypes.any,
};

export default function WithdrawalRequestApprove({ open, view, setOpen, isUpdate }) {
  // const theme = useTheme();
  const role = useGetRoles()?.role;
  const dispatch = useDispatch();
  const [statusValue, setStatusValue] = React.useState();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useResponsive('down', 'md');
  const balance = (isUpdate?.amount || 0) - (isUpdate?.deduction_amount || 0) - 0.5;
  const settings = useSettingsContext();
  // console.log(isUpdate);
  const handleClose = () => {
    setOpen(false);
  };

  const defaultValues = React.useMemo(
    () => ({
      remarks: isUpdate?.remarks || '',
    }),
    [isUpdate]
  );

  const methods = useForm({
    // resolver: yupResolver(memberCreateSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    // formState: { errors },
    // reset,
  } = methods;

  // const handleStatusChange = (value) => {
  //   setStatusValue(value);
  // };

  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);
    const credentialsUpdate = {
      state: {
        ...data,
        id: isUpdate?._id,
        status: statusValue,
      },
      dispatch,
      handleClose,
      // handleClear,
    };
    // if () {
    //   const credentialsUpdate = {
    //     state: {
    //       ...data,
    //       is_active: isActive,
    //       is_verified: isVerified,
    //       is_paid: isPaid,
    //     },
    //     dispatch,

    //     navigate,
    //   };
    // dispatch(updateMemberDetails(credentialsUpdate));
    dispatch(updateWithdrawalRequestAdmin(credentialsUpdate));
  });
  // const router = useRouter();
  console.log(isUpdate);
  const handleCopy = () => {
    enqueueSnackbar('Wallet Address copied!', { variant: 'success' });
  };
  const handleTypeChange = (event) => {
    const updatedStatus = event.target.value;
    setStatusValue(updatedStatus);
  };
  const statusOptions = [
    { label: 'Processing', value: 'PROCESSING' },
    { label: 'In Progress', value: 'IN_PROGRESS' },
    { label: 'Approved', value: 'APPROVED' },
    { label: 'Cancelled', value: 'CANCELLED' },
  ];
  React.useEffect(() => {
    setStatusValue(isUpdate?.status);
  }, [isUpdate?.status]);
  const getColor = () => {
    switch (isUpdate?.status) {
      case 'PROCESSING':
        return 'warning';
      case 'APPROVED':
        return 'success';
      case 'IN_PROGRESS':
        return 'info';
      case 'CANCELLED':
        return 'error';
      default:
        return 'default';
    }
  };
  const handleStatusValues = () => {
    switch (isUpdate?.status) {
      case 'PROCESSING':
        return 'Processing';
      case 'APPROVED':
        return 'Approved';
      case 'IN_PROGRESS':
        return 'In Progress';
      case 'CANCELLED':
        return 'Cancelled';
      default:
        return 'default';
    }
  };
  // const { enqueueSnackbar } = useSnackbar();
  return (
    <div>
      <Dialog
        className="custom-dialog"
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', zIndex: 5, top: 0, right: 0, m: 2 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        {/* <DialogTitle id="responsive-dialog-title" sx={{ mt: isMobile && 2 }}>
        Withdrawal Request Review        </DialogTitle> */}
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogContent sx={{ minWidth: isMobile ? '250px' : '540px', p: 0 }}>
            <Stack direction={isMobile ? 'column' : 'row'} gap={2} p={2}>
              <Stack flex={6} gap={2.5} mx={0.5}>
                <Typography variant="h6">Withdrawal Request Details</Typography>
                <Stack direction="row" gap={2} justifyContent="space-between">
                  <Stack
                    borderRadius="3px"
                    backgroundColor={
                      settings?.themeMode === 'dark' ? 'rgba(145, 158, 171, 0.08)' : '#FFF5CC'
                    }
                    width="fit-content"
                    px={1}
                    py={0.5}
                  >
                    <Typography variant="caption">
                      {' '}
                      Withdrawal ID:
                      <Typography
                        component="span"
                        variant="caption"
                        color={settings?.themeMode === 'dark' ? 'white' : '#7A0916'}
                        fontWeight={settings?.themeMode === 'dark' ? '900' : 'bold'}
                        pl={0.5}
                      >
                        {isUpdate?.request_id}
                      </Typography>
                    </Typography>
                  </Stack>
                  {view && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="caption" color="#637381">
                        Status
                      </Typography>
                      <Label variant="soft" color={getColor()}>
                        <CircleIcon style={{ color: getColor(), fontSize: 10, marginRight: 4 }} />

                        {handleStatusValues()}
                      </Label>
                    </Stack>
                  )}
                </Stack>
                {!view && (
                  <>
                    <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
                      Member Details
                    </Typography>
                    <Stack gap={1}>
                      <Stack direction="row">
                        <Typography minWidth="120px" variant="body2" fontWeight="500">
                          Member ID
                        </Typography>
                        <Typography variant="body2" color="#637381">
                          {isUpdate?.member?.member_id}
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography minWidth="120px" variant="body2" fontWeight="500">
                          Name
                        </Typography>
                        <Typography variant="body2" color="#637381">
                          {isUpdate?.member?.name}
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography minWidth="120px" variant="body2" fontWeight="500">
                          Phone
                        </Typography>
                        <Typography variant="body2" color="#637381">
                          {isUpdate?.member?.country_code}&nbsp;{isUpdate?.member?.phone}
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography minWidth="120px" variant="body2" fontWeight="500">
                          Email
                        </Typography>
                        <Typography variant="body2" color="#637381">
                          {isUpdate?.member?.email}{' '}
                        </Typography>
                      </Stack>
                    </Stack>
                  </>
                )}
                <Stack>
                  <Typography variant="subtitle2" sx={{ fontWeight: '700', mb: 1 }}>
                    Wallet Address
                  </Typography>
                  <Stack gap={1} direction="row">
                    <Card
                      variant="outlined"
                      sx={{
                        borderRadius: '10px',
                        boxShadow: 'unset',
                        textAlign: 'start',
                        gap: 1.8,
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Stack sx={{ pl: 1.5, pr: 2.2, py: 0.5, minWidth: '280px' }}>
                        <Typography fontSize="10px">BEP120</Typography>
                        <TextMaxLine line={1}>
                          <Typography
                            sx={{
                              wordWrap: 'break-word',
                              overflowWrap: 'anywhere',
                              opacity: 0.7,
                              textAlign: 'start',
                              // minWidth: '250px',
                              maxWidth: '250px',
                            }}
                            variant="body2"
                          >
                            {isUpdate?.wallet_address}{' '}
                          </Typography>
                        </TextMaxLine>
                      </Stack>
                    </Card>
                    <Stack
                      sx={{
                        border: settings?.themeMode === 'dark' ? 'none' : '1px solid #e3e7ea',
                        px: 1,
                        borderRadius: '10px',

                        justifyContent: 'center',
                        backgroundColor: settings?.themeMode === 'dark' ? '#e1efea' : '#00A76F14',
                      }}
                    >
                      <CopyToClipboard text={isUpdate?.wallet_address} onCopy={handleCopy}>
                        <Tooltip title="Copy">
                          {/* <Button
                          size="small"
                          borderRadius="25px"
                          className="light-green-copy--btn new remove-hover-color-btn"
                          startIcon={
                            <CardMedia
                              component="img"
                              src={share}
                              alt="copy"
                              sx={{ width: '25px' }}
                            />
                          }
                        >
                          Copy
                        </Button> */}
                          <Stack
                            className="light-green-copy--btn new remove-hover-color-btn"
                            px={1}
                            sx={{ cursor: 'pointer' }}
                          >
                            <CardMedia
                              component="img"
                              src={share}
                              alt="copy"
                              sx={{ width: '30px' }}
                            />
                          </Stack>
                        </Tooltip>
                      </CopyToClipboard>
                    </Stack>
                  </Stack>
                </Stack>
                <RHFTextField
                  name="transaction_hash"
                  label="Transaction Hash"
                  type="string"
                  placeholder="Transaction Hash"
                  // defaultValue={isUpdate?.member?.payment_transaction_id}
                  InputLabelProps={{ shrink: true }}
                  multiline
                  rows={2}
                  InputProps={{
                    readOnly: !!view,
                  }}
                />
                {role === 'admin' && (
                  <Stack mb={2}>
                    <RHFTextField
                      name="remarks"
                      label="Remarks"
                      type="string"
                      placeholder="Enter Remarks"
                      InputLabelProps={{ shrink: true }}
                      rows={2}
                      multiline
                      InputProps={{
                        readOnly: !!view,
                      }}
                    />
                  </Stack>
                )}
                {!view && (
                  <Stack>
                    <Stack>
                      <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
                        Status
                      </Typography>{' '}
                      <ToggleButtonGroup
                        value={statusValue}
                        exclusive
                        onChange={handleTypeChange}
                        size="small"
                        sx={{ border: 'unset' }}
                      >
                        {statusOptions.map((option) => (
                          <ToggleButton
                            className="custom-toggle-btn"
                            key={option.value}
                            value={option.value}
                            sx={{
                              px: 1,
                              py: 0.5,
                              fontSize: '12px',
                              fontWeight: option.value === statusValue ? 600 : 400,
                              whiteSpace: 'nowrap',
                              backgroundColor:
                                option.value === statusValue ? 'lightgreen' : 'white',
                              color: option.value === statusValue ? 'black' : 'gray',
                              '&.Mui-selected': {
                                backgroundColor: '#d3fcd2',
                                color: '#007867',
                                border: '1px solid #d3e8d8',
                              },
                              '&:hover': {
                                backgroundColor: '#d3fcd2',
                              },
                              borderRadius: '15px',
                              marginRight: 1,
                              border: '1px solid #d3e8d8',
                            }}
                          >
                            {option.label}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </Stack>
                  </Stack>
                )}
              </Stack>
              <Card
                sx={{
                  backgroundColor: '#EDFAF6',
                  p: 2,
                  flex: 6,
                  boxShadow: 'unset',
                  borderRadius: '8px',
                  width: '100%',
                }}
              >
                <Typography variant="subtitle2" color="black" mb={view && 3}>
                  Summary
                </Typography>
                {!view && (
                  <Stack
                    borderRadius="3px"
                    backgroundColor={
                      settings?.themeMode === 'dark'
                        ? 'rgba(145, 158, 171, 0.08)'
                        : 'rgba(145, 158, 171, 0.08)'
                    }
                    px={1}
                    py={0.5}
                    my={2}
                  >
                    <Typography variant="caption" textAlign="center" color="black">
                      {' '}
                      Wallet Balance :
                      <Typography
                        component="span"
                        variant="caption"
                        color="#007867"
                        fontWeight="bold"
                        pl={0.2}
                      >
                        {`${isUpdate?.member?.wallet_balance?.toFixed(2) || 0} ${USDT}`}
                      </Typography>
                    </Typography>
                  </Stack>
                )}
                <Stack gap={1}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography minWidth="150px" color="#637381" variant="body2">
                      Withdrawal Amount
                    </Typography>
                    <Typography variant="body2" color="#637381">
                      {isUpdate?.amount} {USDT}{' '}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography minWidth="150px" color="#637381" variant="body2">
                      Deduction Amount{' '}
                    </Typography>
                    <Typography variant="body2" color="#637381">
                      -{isUpdate?.deduction_amount} {USDT}{' '}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography minWidth="150px" color="#637381" variant="body2">
                      Gas Fee{' '}
                    </Typography>
                    <Typography variant="body2" color="#637381">
                      -0.5 {USDT}{' '}
                    </Typography>
                  </Stack>
                  <Divider />
                  <Stack direction="row" justifyContent="space-between">
                    <Typography color="black" minWidth="150px" fontWeight="bold" variant="body2">
                      Total{' '}
                    </Typography>
                    <Typography variant="body2" color="black" fontWeight="bold">
                      {balance} {USDT}
                    </Typography>
                  </Stack>
                </Stack>
              </Card>
            </Stack>
            {!view && (
              <>
                <Divider sx={{ mb: 2 }} />
                <Stack direction="row" justifyContent="end" spacing={1} pb={1.5} px={2}>
                  <LoadingButton
                    type="submit"
                    size="small"
                    variant="contained"
                    color="success"
                    sx={{
                      px: 2,
                      '&:hover': { backgroundColor: '#044f45' },
                      backgroundColor: '#007867',
                    }}
                  >
                    Update
                  </LoadingButton>
                  <Button variant="outlined" size="small" onClick={handleClose}>
                    Cancel{' '}
                  </Button>
                </Stack>
              </>
            )}
          </DialogContent>
        </FormProvider>
      </Dialog>
    </div>
  );
}
