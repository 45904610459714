/* eslint-disable import/no-unresolved */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import QuizIcon from '@mui/icons-material/Quiz';
import GroupsIcon from '@mui/icons-material/Groups';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import { paths } from 'src/routes/routes/paths';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify/iconify';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

// ----------------------------------------------------------------------

export function useNavData() {
  const { role } = useGetRoles();

  const { MemberProfileDetails } = useSelector((state) => ({
    sidebarCounts: state.dashboard.sidebarCounts,
    MemberProfileDetails: state.member.memberProfileDetails.member,
  }));
  // console.log(MemberProfileDetails);
  // const counts = sidebarCounts?.dashboardCounts;

  // console.log(counts);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const adminSideBarData = useMemo(() => [
    {
      subheader: 'Dashboard',
      items: [
        { title: 'Dashboard', path: `${paths.dashboard.root}/app`, icon: <DashboardIcon /> },

        {
          title: 'Members',
          path: paths.dashboard.member,
          icon: <GroupsIcon />,

          // info: <Label color="warning">{counts?.totalSalesCount || 0}</Label>,
        },
        {
          title: 'Clubs',
          path: `${paths.dashboard.club}?search=club`,
          icon: icon('ic_analytics'),
          children: [
            {
              title: 'All Clubs',
              path: `${paths.dashboard.club}?search=club`,
            },
            {
              title: 'Club members',
              path: `${paths.dashboard.club}?search=club-members`,
            },
          ],
        },

        // { title: 'Clubs', path: paths.dashboard.club, icon: icon('ic_analytics') },
        {
          title: 'Incentive',
          path: paths.dashboard.incentive,
          icon: <Iconify icon="mdi:recurring-payment" sx={{ width: 28, height: 28 }} />,
        },

        {
          title: 'Bionium',
          path: paths.dashboard.bionium,
          icon: <Iconify icon="streamline:dollar-coin-1-solid" sx={{ width: 28, height: 28 }} />,
        },

        {
          title: 'Withdrawals',
          path: `${paths.dashboard.withdrawal_requests}?search=withdrawals`,
          icon: icon('ic_banking'),
          children: [
            {
              title: 'All Withdrawals',
              path: `${paths.dashboard.withdrawal_requests}?search=withdrawals`,
            },
            {
              title: 'Approved List',
              path: `${paths.dashboard.withdrawal_requests}?search=approvedList`,
              // path: paths.dashboard.withdrawal_report,
            },
          ],
        },
        {
          title: 'Transactions',
          path: paths.dashboard.transaction,
          icon: <ChangeCircleIcon />,
        },
        {
          title: 'Enquiries',
          path: paths.dashboard.enquiry,
          icon: <RateReviewIcon />,

          // info: <Label color="warning">{counts?.totalSalesCount || 0}</Label>,
        },
        {
          title: 'Notifications',
          path: paths.dashboard.notification,
          icon: <NotificationsActiveIcon />,

          // info: <Label color="warning">{counts?.totalSalesCount || 0}</Label>,
        },
        {
          title: 'FAQ',
          path: paths.dashboard.faq,
          icon: <QuizIcon />,

          // info: <Label color="warning">{counts?.totalSalesCount || 0}</Label>,
        },

        {
          title: 'Profile',
          path: paths.dashboard.profile,
          icon: icon('ic_user'),
        },
        {
          title: 'Settings',
          path: paths.dashboard.settings,
          icon: <Iconify icon="solar:settings-bold-duotone" width={24} />,
          // info: <Label color="warning">{counts?.totalSalesCount || 0}</Label>,
        },
      ],
    },
  ]);

  const memberSideBarData = useMemo(
    () => [
      {
        subheader: 'Overview',
        items: [
          { title: 'Dashboard', path: `${paths.dashboard.root}/app`, icon: icon('ic_dashboard') },
          {
            title: 'Profile',
            path: MemberProfileDetails?.is_verified ? paths.dashboard.profile : '#',
            disabled: MemberProfileDetails?.is_verified ? '' : 'true',

            icon: icon('ic_user'),
          },
          {
            title: 'Referrals',
            icon: icon('ic_invoice'),
            path: MemberProfileDetails?.is_verified ? paths.dashboard.referral : '#',
            disabled: MemberProfileDetails?.is_verified ? '' : 'true',
          },

          {
            title: 'Earnings',
            path: MemberProfileDetails?.is_verified ? paths.dashboard.income : '#',
            disabled: MemberProfileDetails?.is_verified ? '' : 'true',

            icon: icon('ic_analytics'),
          },
          {
            title: 'Leaderboard',
            path: MemberProfileDetails?.is_verified ? paths.dashboard.leaderboard : '#',
            disabled: MemberProfileDetails?.is_verified ? '' : 'true',

            icon: <EmojiEventsRoundedIcon />,
          },
          {
            title: 'Learnings',
            path: MemberProfileDetails?.is_verified ? paths?.dashboard?.learning : '#',
            disabled: MemberProfileDetails?.is_verified ? '' : 'true',

            icon: <LocalLibraryIcon />,
          },
          {
            title: 'My Ranks',
            path: MemberProfileDetails?.is_verified ? paths.dashboard.incentive : '#',
            icon: <Iconify icon="mdi:recurring-payment" sx={{ width: 28, height: 28 }} />,
            disabled: MemberProfileDetails?.is_verified ? '' : 'true',
          },

          {
            title: 'Bionium',
            path: MemberProfileDetails?.is_verified ? paths.dashboard.bionium : '#',
            disabled: MemberProfileDetails?.is_verified ? '' : 'true',

            icon: <Iconify icon="streamline:dollar-coin-1-solid" sx={{ width: 28, height: 28 }} />,
          },
          {
            title: 'Withdrawals',
            icon: icon('ic_banking'),
            path: MemberProfileDetails?.is_verified ? paths.dashboard.withdrawal_requests : '#',
            disabled: MemberProfileDetails?.is_verified ? '' : 'true',
          },
          {
            title: 'Transactions',
            icon: icon('ic_blog'),
            path: MemberProfileDetails?.is_verified ? paths.dashboard.transaction : '#',
            disabled: MemberProfileDetails?.is_verified ? '' : 'true',
          },
        ],
      },
    ],
    [MemberProfileDetails?.is_verified]
  );

  const data = useMemo(() => {
    const handleSideBarDataWithRole = () => {
      switch (role) {
        case 'admin':
          return adminSideBarData;
        case 'member':
          return memberSideBarData;
        default:
          return [];
      }
    };

    return handleSideBarDataWithRole();
  }, [adminSideBarData, memberSideBarData, role]);

  return data;
}

// const ICONS = {
//   job: icon('ic_job'),
//   blog: icon('ic_blog'),
//   chat: icon('ic_chat'),
//   mail: icon('ic_mail'),
//   user: icon('ic_user'),
//   file: icon('ic_file'),
//   lock: icon('ic_lock'),
//   tour: icon('ic_tour'),
//   order: icon('ic_order'),
//   label: icon('ic_label'),
//   blank: icon('ic_blank'),
//   kanban: icon('ic_kanban'),
//   folder: icon('ic_folder'),
//   banking: icon('ic_banking'),
//   booking: icon('ic_booking'),
//   invoice: icon('ic_invoice'),
//   product: icon('ic_product'),
//   calendar: icon('ic_calendar'),
//   disabled: icon('ic_disabled'),
//   external: icon('ic_external'),
//   menuItem: icon('ic_menu_item'),
//   ecommerce: icon('ic_ecommerce'),
//   analytics: icon('ic_analytics'),
//   dashboard: icon('ic_dashboard'),
// };
