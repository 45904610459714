import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { Box, Card, Stack, Alert, Container, Typography } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { ResentOtpApi, verifyRegisterOtpApi } from 'src/server/api/auth';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';
import { useSettingsContext } from 'src/components/settings';
import FormProvider from 'src/components/hook-form/form-provider';

export default function SignUpOTPView() {
  const isMobile = useResponsive('down', 'md');

  const router = useRouter();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const settings = useSettingsContext();

  const { loading, ResentRegisterOTP, loadingRegister } = useSelector((state) => ({
    ResentRegisterOTP: state.auth.ResentRegisterOTP,
    loading: state.auth.loading,
    loadingRegister: state.auth.loadingRegister,
  }));
  console.log(ResentRegisterOTP);
  const registerSchema = Yup.object().shape({
    otp: Yup.number().min(4, 'OTP must be at least 4 digits').required('OTP is required'),
  });

  useEffect(() => {
    if (ResentRegisterOTP?.email) {
      sessionStorage.setItem('memberEmail', ResentRegisterOTP?.email);
    }
  }, [ResentRegisterOTP?.email]);
  const defaultValues = {};
  // console.log(selectedCountryCode);
  const methods = useForm({
    resolver: yupResolver(registerSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const otp = String(data?.otp);
    const credentials = {
      state: {
        otp,
        userId: ResentRegisterOTP?.user,
      },
      dispatch,
      navigate,
    };
    // console.log(credentials)
    dispatch(verifyRegisterOtpApi(credentials));
  });

  const HandleLogin = () => {
    router.push({
      pathname: paths?.auth?.login_member,
    });
  };
  const [timeLeft, setTimeLeft] = useState(15 * 60);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const handleReset = () => {
    if (ResentRegisterOTP?.email) {
      const otpResetCrud = {
        state: {
          email: ResentRegisterOTP?.email,
        },
        type: 'resend_otp',
        dispatch,
      };
      dispatch(ResentOtpApi(otpResetCrud));
    }
  };
  const renderForm = (
    <>
      <Stack direction="row" gap={2} alignItems="center">
        <Box
          sx={{
            border: '1px dashed',
            borderColor: 'grey.400',
            borderRadius: 1,
            padding: 1.5,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Box>
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{
                position: 'absolute',
                backgroundColor: settings?.themeMode === 'dark' ? '#443333' : 'white',

                top: -10,
                left: 12,
              }}
            >
              Email
            </Typography>
            <Typography color="#637381" variant="caption">
              {ResentRegisterOTP?.email}{' '}
            </Typography>
          </Box>
        </Box>

        <Typography
          variant="body2"
          component={Link}
          color="#007867"
          to={paths?.auth?.register}
          sx={{ textDecoration: 'unset' }}
        >
          Edit
        </Typography>
      </Stack>
      <Stack mt={2}>
        <Typography variant="caption" my={1}>
          Enter OTP here
        </Typography>
        {/* <RHFCode name="otp" mb={2} /> */}
        <RHFTextField name="otp" label="OTP" type="number" placeholder="Enter Your OTP" mb={2} />
        <Alert
          className="alert-custom"
          icon={<Iconify icon="material-symbols:info" sx={{ color: '#00A76F' }} />}
          sx={{
            bgcolor: '#F2F8F6',
            color: '#00A76F',
            borderRadius: '10px',
            display: 'inline-flex',
            alignItems: 'center',
            py: 1.5,
            px: 1,
            mt: 2,
            my: 2,
            width: '100%',
          }}
        >
          <Typography
            color="#637381"
            sx={{
              fontSize: isMobile ? '12px' : '14px',
              fontWeight: 500,
              display: 'flex',
              gap: 0.5,
              alignItems: 'center',
            }}
          >
            An OTP has been sent to this email.
          </Typography>
        </Alert>
        <Typography variant="caption" textAlign="center" pb={2} color="#637381">
          Didn&apos;t receive an OTP?{' '}
          {seconds > 0 && (
            <Typography color="black" variant="caption" fontWeight="bold" component="span">
              Resend in {`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}{' '}
            </Typography>
          )}
        </Typography>
      </Stack>
      <Stack py={3} pt={0} flexDirection="column" gap={2} justifyContent="end">
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: '#007867',
            width: '100%',
            height: '',
            ':hover': { backgroundColor: '#2ca46c' },
          }}
          loading={isSubmitting || loadingRegister}
        >
          Verify OTP
        </LoadingButton>
        <LoadingButton
          loading={isSubmitting || loading}
          variant="outlined"
          size="large"
          sx={{ width: '100%', ':hover': { backgroundColor: '#2ca46c' } }}
          onClick={handleReset}
          disabled={timeLeft !== 0}
        >
          Resend OTP
        </LoadingButton>
      </Stack>
    </>
  );
  return (
    <Container maxWidth="lg">
      <MetaHelmet title="Winbuks" miniDescription="Member Registration" />

      <Stack maxWidth={420} marginX="auto">
        <Card variant="outlined" sx={{ p: 2, mt: 2, boxShadow: ' 0px 6px 2px 0px #E8ECEB' }}>
          <Stack alignItems="center" my={3} gap={1}>
            <Typography variant="h4" ontWeight={700}>
              Sign up to join Winbuks{' '}
            </Typography>
            <Typography fontWeight={400} color="#637381" display="flex" variant="caption">
              Provide your email to continue with the registration.{' '}
            </Typography>
          </Stack>
          <Stack p={isMobile ? 0 : 1} py={isMobile ? 3 : 3}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              {renderForm}
            </FormProvider>
            <Stack alignItems="center" textAlign="center">
              <Typography
                fontSize={isMobile ? 12 : 14}
                fontWeight={400}
                color="#637381"
                display="flex"
              >
                Already have an account?{' '}
                <Typography
                  fontSize={isMobile ? 12 : 14}
                  color="#007867"
                  ml="5px"
                  sx={{ cursor: 'pointer', textDecoration: 'underLine' }}
                  onClick={HandleLogin}
                >
                  Login
                </Typography>
              </Typography>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
}
