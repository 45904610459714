import React from 'react';
import { m } from 'framer-motion';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Stack, Button, Avatar, Typography, IconButton } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { varHover } from 'src/components/animate';
import TextMaxLine from 'src/components/text-max-line';
import { ConfirmDialog } from 'src/components/custom-dialog';

export default function LeaderboardTableRow({ row, selected, onEditRow, markPosition }) {
  const { rank, name, member_id, earnings_total } = row;
  const confirm = useBoolean();

  // console.log(account);
  const getInitials = (title) => {
    const names = title?.split(' ');
    return names?.map((n) => n.charAt(0).toUpperCase()).join('');
  };

  const colorSetter = () => {
    if (!name) return '#CCCCCC';

    const parts = name.trim().split(' ');
    const keyWord = parts.length > 1 ? parts[1] : parts[0];
    const initial = keyWord.charAt(0).toUpperCase();
    switch (initial) {
      case 'M':
        return '#8E33FF';
      case 'R':
        return '#00A76F';
      case 'A':
        return '#FF5733';
      case 'B':
        return '#FFC300';
      case 'C':
        return '#DAF7A6';
      case 'D':
        return '#33C4FF';
      case 'E':
        return '#FF33B8';
      case 'F':
        return '#FF3333';
      case 'S':
        return '#00A7FF';
      case 'T':
        return '#6A33FF';
      case 'G':
        return '#4CAF50';
      case 'H':
        return '#FF9800';
      case 'I':
        return '#9C27B0';
      case 'J':
        return '#FFEB3B';
      case 'K':
        return '#607D8B';
      case 'L':
        return '#795548';
      case 'N':
        return '#2196F3';
      case 'O':
        return '#3F51B5';
      case 'P':
        return '#FF5722';
      case 'Q':
        return '#009688';
      case 'U':
        return '#673AB7';
      case 'V':
        return '#03A9F4';
      case 'W':
        return '#8BC34A';
      case 'X':
        return '#CDDC39';
      case 'Y':
        return '#FFEB3B';
      case 'Z':
        return '#F44336';
      default:
        return '#CCCCCC';
    }
  };

  const isHighlighted = markPosition === row?._id;

  return (
    <>
      <TableRow
        // hover
        selected={selected}
        sx={{
          backgroundColor: isHighlighted ? '#F2FAED' : undefined,
          boxShadow: isHighlighted
            ? 'inset 0px 2px 4px rgba(0, 0, 0, 0.12), inset 0px -2px 4px rgba(0, 0, 0, 0.12)'
            : undefined,
        }}
      >
        <TableCell>
          <Stack>
            <Typography variant="body2" color="#2e436a" sx={{ cursor: 'pointer' }}>
              {rank || '--'}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <TextMaxLine line={2}>
            <Typography variant="body2" color={isHighlighted && '#2e436a'}>
              <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                sx={{
                  width: 40,
                  height: 40,
                }}
              >
                <Avatar
                  sx={{
                    width: 36,
                    color: 'white',
                    height: 36,
                    borderRadius: '50%',

                    backgroundColor: colorSetter(),

                    border: (theme) => `solid 2px ${theme.palette.background.default}`,
                  }}
                >
                  <Typography variant="caption" color="white">
                    {' '}
                    {getInitials(name)}
                  </Typography>{' '}
                </Avatar>
              </IconButton>{' '}
              {name || '--'}
            </Typography>
          </TextMaxLine>
        </TableCell>
        <TableCell>
          <Stack>
            <Typography
              variant="body2"
              color={isHighlighted && '#212B36'}
              sx={{ cursor: 'pointer' }}
            >
              {member_id || '--'}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack>
            <Typography
              variant="body2"
              color={isHighlighted && '#212B36'}
              sx={{ cursor: 'pointer' }}
            >
              {earnings_total?.toFixed(0) || '--'} USDT
            </Typography>
          </Stack>
        </TableCell>
      </TableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete FAQ"
        icon={<WarningAmberRoundedIcon color="error" fontSize="large" />}
        content="Are you sure you want to delete this FAQ? This action cannot be undone."
        alertMsg="Deleting this FAQ will remove it from the Winbuks website."
        action={
          <Button
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: '#7A0916',
            }}
            // onClick={handleDeleteFaqConfirmClick}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

LeaderboardTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.any,
  markPosition: PropTypes.any,
};
